import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Full_ROUTES } from './routes/full-layout.routes';
import { CONTENT_ROUTES } from './routes/content-layout.routes';
import {
  FullLayoutComponent,
  ContentLayoutComponent,
  AuthGuard,
} from 'shared-lib';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'celebrities',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { title: 'affiliate' },
    children: Full_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    data: { title: 'affiliate' },
    children: CONTENT_ROUTES,
  },
  //Wild Card Route for 404 request
  // { path: '**', pathMatch: 'full', component: ErrorComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
