import { Routes } from '@angular/router';
import { isLoggedIn } from 'shared-lib';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: () => import('shared-lib').then((m) => m.AuthModule),
    data: { title: 'affiliate' },
    canActivate: [isLoggedIn],
  },
];
