import { Routes } from '@angular/router';

export const Full_ROUTES: Routes = [
  {
    path: 'celebrities',
    loadChildren: () => import('shared-lib').then((m) => m.CelebritiesModule),
    data: { title: 'affiliate' },
  },
  {
    path: 'transactions',
    loadChildren: () => import('shared-lib').then((m) => m.TransactionsModule),
    data: { title: 'affiliate' },
  },
  {
    path: 'products',
    loadChildren: () => import('shared-lib').then((m) => m.ProductsModule),
    data: { title: 'affiliate' },
  },
];
